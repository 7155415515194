<template>
  <div class="shengci">生词表</div>
  <div class="shengciEn">
    Vocabulary List
    <!-- 头部 -->
    <div class="headers">
      <div class="languageChange">
        <el-dropdown @command="handleCommand" size="small">
          <el-button type="primary" size="small"
            >{{ def_grade
            }}<el-icon class="el-icon--right"
              ><svg
                t="1641818630955"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2112"
                width="16"
                height="16"
              >
                <path
                  d="M966.4 323.2c-9.6-9.6-25.6-9.6-35.2 0l-416 416-425.6-416c-9.6-9.6-25.6-9.6-35.2 0-9.6 9.6-9.6 25.6 0 35.2l441.6 432c9.6 9.6 25.6 9.6 35.2 0l435.2-432C976 345.6 976 332.8 966.4 323.2z"
                  p-id="2113"
                  fill="#ffffff"
                ></path></svg></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="(item, key) in grade"
                :key="key"
                :command="item"
              >
                {{ item }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
  
  <!-- 词部分 -->
  <div v-for="(item, index) in grade_word" :key="index">
    <div class="word">
      <div class="word_title">
        <div>{{ item.word }}</div>
        <!-- 听读音 -->
        <span class="ListenWord" @click="talk_word(item.luyin, index)">
          <img
            ref="PauseAudio"
            src="https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/read/听读音.png"
          />
        </span>
        <!-- 书写 -->
        <span class="VoiceRecognition" @click="showOverlay(item.word)"> </span>
        <!-- 语音识别 -->
        <span class="wirte" @click="VoiceRecognition(item.word)"></span>
      </div>
      <ul class="word_list">
        <li>
          <span class="colorFor"> 拼音：</span>
          <span style="color: #868686">{{ item.pinyin }}</span>
        </li>
        <li>
          <span class="colorFor"> 级别：</span
          ><span> {{ item.wordActive }} </span>
        </li>
        <li>
          <span class="colorFor">解释：</span
          ><span style="color: #00bfbd"> {{ item.yiwen }} </span>
        </li>
      </ul>
    </div>
  </div>
  <!-- 遮罩层 -->
  <van-overlay :show="show" :z-index="9">
    <Write v-if="WriteShow" @close="closeOverlay" :propText="propText" />
    <VoiceRecognition v-if="show" @close="closeOverlay2" :propText="propText" />
  </van-overlay>
  <audio ref="readAudio"></audio>
</template>

<script>
import { reactive, toRefs,ref } from "vue";
import {ElDropdown,ElButton,ElIcon,ElDropdownItem,ElDropdownMenu} from "element-plus";
import axios from "axios";
import Write from "./Write.vue";
import VoiceRecognition from './VoiceRecognition.vue';
import Url from "../CommonAsset/js/config";
export default {
    components: {
    ElDropdown,
    ElButton,
    ElIcon,
    ElDropdownItem,
    ElDropdownMenu,
    Write,
    VoiceRecognition
  },
  emits: ["close"],
  setup(props,context) {
    let id = localStorage.getItem("id");
    let lang = localStorage.getItem("lang");
    let readTextAudio = ref(null);
    let PauseAudio = ref(null);
    let readAudio = ref(null);
    const word = reactive({
      WordUrl: "/api/data.php?action=getWordData&",
      textWord: [],
      grade_word: [],
      word_index: "",
      player: true,
      pause: false,
      test: true,
      def_grade: "全部",
      grade: ["一级", "二级", "三级", "全部"],
    });
    // 选择语言
    function handleCommand(grade) {
      word.def_grade = grade;
      if (grade == "全部") {
        word.grade_word = [];
        word.grade_word = word.textWord;
      } else {
        word.grade_word = [];
        word.grade_word = filterByGrade(grade);
      }
    }
    function filterByGrade(grade) {
      return word.textWord.filter((item) => item.wordActive == grade);
    }
    // 单词板块请求数据
    getWord();
    async function getWord() {
      const res = await axios.get(
        Url + word.WordUrl + `contentId=${id}&lang=${lang}`
      );
      // 全部的单词数据
      word.textWord = res.data.word;
      // 展示用的单词数据
      word.grade_word = res.data.word;
    }

    function talk_word(luyin, index) {
      PauseAudio.value.forEach((e) => {
        e.style.display = "block";
      });
      // 点击播放后，喇叭禁止样式取消
      PauseAudio.value[index].style.display = "none";
      //============================================================11.2播放课文的时候，点击单词，暂停课文阅读
      context.emit("close");
      word.player = true;
      word.pause = false;
      if (word.word_index !== index) {
        word.test = true;
      }
      word.word_index = index;
      if (word.test) {
        word.test = false;
        readAudio.value.src = luyin;
        readAudio.value.play();

        readAudio.value.addEventListener("ended", () => {
          word.test = true;
          // 声音播放完毕后
          PauseAudio.value[index].style.display = "block";
        });
      }
    }
     // -------------------------------------------------------------10-31新增内容
    // 遮罩
    const show = ref(false);
    // 书写
    const WriteShow = ref(false)
    // 语音
    const Voice = ref(false);
    let propText = ref();
    // 《书写》允许子组件关闭
    let closeOverlay = () => {
      show.value = false;
      WriteShow.value = false
    };
    // 《读音》允许子组件关闭
    let closeOverlay2 = () => {
      show.value = false;
      Voice.value = false
    };
    // 控制是否书写开关
    let showOverlay = (text) =>{
      context.emit("close");
      WriteShow.value = true
      show.value = true;
      propText.value = text
    }
    // 控制语音识别开关
    let VoiceRecognition = (text) =>{
      context.emit("close");
      show.value = true;
      Voice.value = true;
      propText.value = text
    }
    return {
      ...toRefs(word),
      talk_word,
      handleCommand,
      closeOverlay,
      closeOverlay2,
      showOverlay,
      VoiceRecognition,
      show,
      propText,
      WriteShow,
      PauseAudio,
      readTextAudio,
      readAudio
    };
  },
};
</script>

<style scoped>
.shengci {
  width: 100%;
  height: 30px;
  text-align: center;
  font-size: 24px;
  color: #080808;
}
.shengciEn {
  position: relative;
  text-align: center;
  color: #1a7708;
}
.headers {
  position: absolute;
  right: 8%;
  top: 1%;
  z-index: 6;
}
.word {
  margin: 0 auto;
  margin-top: 2%;
  width: 90%;
  height: 165px;
  background-color: #fefef8;
  border: 1px solid #cccccc;
  border-radius: 20px;
}
.word_title {
  display: flex;
  width: 90%;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  font-size: 24px;
  color: #003c90;
  font-family: "宋体";
  border-bottom: 1px solid #cccccc;
}
.word_list {
  width: 90%;
  margin: 0 auto;
  margin-top: 5%;
}
.word_list > li {
  margin-top: 4%;
}
.colorFor {
  color: #3d3d3d;
}
.ListenWord {
  position: relative;
  top: 20%;
  left: 3%;
  width: 24px;
  height: 24px;
  background: url("https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/read/听读音动画.png")
    no-repeat;
  animation: player 1s steps(4) infinite;
}
.ListenWord > img {
  position: absolute;
  top: 0%;
  left: 0%;
}
/* --------------------------------10.28新增代码 */
.VoiceRecognition {
  position: relative;
  top: 20%;
  left: 5%;
  width: 24px;
  height: 24px;
  background: url("../CommonAsset/img/write.png") no-repeat;
}
.wirte {
  position: relative;
  top: 20%;
  left: 8%;
  width: 24px;
  height: 24px;
  background: url("../CommonAsset/img/voice_recognize.png") no-repeat;
}
</style>
