import { createApp } from "vue";
import "element-plus/dist/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Overlay, Button } from "vant";
import "./CommonAsset/css/default.css";
// 2. 引入组件样式
import "vant/lib/index.css";
createApp(App)
  .use(store)
  .use(router)
  .use(Overlay)
  .use(Button)
  .mount("#app");
